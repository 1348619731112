import React, { useState } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const VideoFeedbackForm = ({
  videoId,
  onSubmitFeedback,
  feedbackSubmitted,
}) => {
  const [visualClarity, setVisualClarity] = useState(3);
  const [depthPerception, setDepthPerception] = useState(3);
  const [easeOfInteraction, setEaseOfInteraction] = useState(3);
  const [overallExperience, setOverallExperience] = useState(3);
  const [comments, setComments] = useState("");
  const [submitted, setSubmitted] = useState(feedbackSubmitted);

  const [showTooltip, setShowTooltip] = useState({
    visualClarity: false,
    depthPerception: false,
    easeOfInteraction: false,
    overallExperience: false,
  });

  const ratingDescriptions = [
    "Very Poor Quality",
    "Poor Quality",
    "Fair Quality",
    "Good Quality",
    "Very Good Quality",
    "Excellent Quality",
  ];

  const handleHover = (field) => {
    setShowTooltip((prev) => ({ ...prev, [field]: true }));
  };

  const handleLeave = (field) => {
    setShowTooltip((prev) => ({ ...prev, [field]: false }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const feedback = {
      visual_clarity: visualClarity,
      depth_perception: depthPerception,
      ease_of_interaction: easeOfInteraction,
      overall_experience: overallExperience,
      comments,
    };

    if (window.confirm("Are you sure you want to submit your feedback?")) {
      onSubmitFeedback(videoId, feedback);
      setSubmitted(true);
    }
  };

  if (submitted) {
    return (
      <p className="feedback-submitted">Feedback submitted for this video.</p>
    );
  }

return (
  <div className="feedback-form hover-card transparent-card">
    <h6 className="mt-3 mb-4 text-center text-warning">Adjust the Sliders to Evaluate Each Aspect of the Video</h6>
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Visual Clarity (0-5): {visualClarity * 20}%</label>
        <OverlayTrigger
          show={showTooltip.visualClarity}
          placement="top"
          overlay={<Tooltip>{ratingDescriptions[visualClarity]}</Tooltip>}>
          <input
            type="range"
            min="0"
            max="5"
            value={visualClarity}
            onChange={(e) => setVisualClarity(Number(e.target.value))}
            onMouseEnter={() => handleHover("visualClarity")}
            onMouseLeave={() => handleLeave("visualClarity")}
            className="form-range mt-2 mb-2"
          />
        </OverlayTrigger>
      </div>

      <div className="form-group">
        <label>Depth Perception (0-5): {depthPerception * 20}%</label>
        <OverlayTrigger
          show={showTooltip.depthPerception}
          placement="top"
          overlay={<Tooltip>{ratingDescriptions[depthPerception]}</Tooltip>}>
          <input
            type="range"
            min="0"
            max="5"
            value={depthPerception}
            onChange={(e) => setDepthPerception(Number(e.target.value))}
            onMouseEnter={() => handleHover("depthPerception")}
            onMouseLeave={() => handleLeave("depthPerception")}
            className="form-range mb-2"
          />
        </OverlayTrigger>
      </div>

      <div className="form-group">
        <label>Ease of Interaction (0-5): {easeOfInteraction * 20}%</label>
        <OverlayTrigger
          show={showTooltip.easeOfInteraction}
          placement="top"
          overlay={<Tooltip>{ratingDescriptions[easeOfInteraction]}</Tooltip>}>
          <input
            type="range"
            min="0"
            max="5"
            value={easeOfInteraction}
            onChange={(e) => setEaseOfInteraction(Number(e.target.value))}
            onMouseEnter={() => handleHover("easeOfInteraction")}
            onMouseLeave={() => handleLeave("easeOfInteraction")}
            className="form-range mb-2"
          />
        </OverlayTrigger>
      </div>

      <div className="form-group">
        <label>Overall Experience (0-5): {overallExperience * 20}%</label>
        <OverlayTrigger
          show={showTooltip.overallExperience}
          placement="top"
          overlay={<Tooltip>{ratingDescriptions[overallExperience]}</Tooltip>}>
          <input
            type="range"
            min="0"
            max="5"
            value={overallExperience}
            onChange={(e) => setOverallExperience(Number(e.target.value))}
            onMouseEnter={() => handleHover("overallExperience")}
            onMouseLeave={() => handleLeave("overallExperience")}
            className="form-range mb-2"
          />
        </OverlayTrigger>
      </div>

      <div className="form-group">
        <label>Additional Comments:</label>
        <OverlayTrigger
          show={showTooltip.comments}
          placement="top"
          overlay={<Tooltip>Provide any additional feedback or observations.</Tooltip>}>
          <textarea
            className="form-control mb-5"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            onMouseEnter={() => handleHover("comments")}
            onMouseLeave={() => handleLeave("comments")}
          ></textarea>
        </OverlayTrigger>
      </div>

      <button type="submit" className="btn btn-submit mt-3 mb-2 btn-submit w-100">
        Submit Feedback
      </button>
    </form>
  </div>
);

};

export default VideoFeedbackForm;
