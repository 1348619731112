import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signup from "./components/Signup";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import LandingPage from "./components/LandingPage";
import Questionnaire from "./components/Questionnaire";
import VideoPlayer from "./components/VideoPlayer";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import AdminLandingPage from "./admin_components/AdminLandingPage";
import AdminLogin from "./admin_components/AdminLogin";
import AdminDashboard from "./admin_components/AdminDashboard";
import AdminUsers from "./admin_components/AdminUsers";
import AppWrapper from "./AppWrapper"; // Import the new AppWrapper
import AdminVideos from "./admin_components/AdminVideos";
import AdminFeedback from "./admin_components/AdminFeedback";
import AdminViewFeedback from "./admin_components/AdminViewFeedback";
import ContactForm from "./components/ContactForm";
import AdminMessages from "./admin_components/AdminContactMessages";
import AdminQuestionaireList from "./admin_components/AdminQuestionnaireList";
import AdminViewQuestionaire from "./admin_components/AdminViewQuestionnaire";
import DistortionExamples from "./components/DistortionExamples";
// Import the combined PrivateRoute component
import PrivateRoute from "./components/PrivateRoute"; // Add the PrivateRoute import

function App() {
  return (
    <Router>
      <AppWrapper>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />

          {/* Protected User Routes */}
          <Route
            path="/dashboard"
            element={<PrivateRoute element={<Dashboard />} role="user" />}
          />
          <Route
            path="/questionnaire"
            element={<PrivateRoute element={<Questionnaire />} role="user" />}
          />
          <Route
            path="/rate-videos"
            element={<PrivateRoute element={<VideoPlayer />} role="user" />}
          />
          <Route
            path="/contact"
            element={<PrivateRoute element={<ContactForm />} role="user" />}
          />
          <Route
            path="/distortion-examples"
            element={
              <PrivateRoute element={<DistortionExamples />} role="user" />
            }
          />

          {/* Admin Routes */}
	  <Route path="/admin" element={<AdminLandingPage />} role="admin" />
          <Route path="/admin/login" element={<AdminLogin />} />

         {/* Protected Admin Routes */}
          <Route
            path="/admin/dashboard"
            element={<PrivateRoute element={<AdminDashboard />} role="admin" />}
          />
          <Route
            path="/admin/users"
            element={<PrivateRoute element={<AdminUsers />} role="admin" />}
          />
          <Route
            path="/admin/videos"
            element={<PrivateRoute element={<AdminVideos />} role="admin" />}
          />
          <Route
            path="/admin/view-feedback/:video_id"
            element={<PrivateRoute element={<AdminFeedback />} role="admin" />}
          />
          <Route
            path="/admin/view-feedback/"
            element={
              <PrivateRoute element={<AdminViewFeedback />} role="admin" />
            }
          />
          <Route
            path="/admin/messages/"
            element={<PrivateRoute element={<AdminMessages />} role="admin" />}
          />
          <Route
            path="/admin/questionaire-list/"
            element={
              <PrivateRoute element={<AdminQuestionaireList />} role="admin" />
            }
          />
          <Route
            path="/admin/questionnaire/:id"
            element={
              <PrivateRoute element={<AdminViewQuestionaire />} role="admin" />
            }
          />
        </Routes>
      </AppWrapper>
    </Router>
  );
}

export default App;

