// src/components/PrivateRoute.js

import React from "react";
import { Route, Navigate } from "react-router-dom";

const PrivateRoute = ({ element, role, ...rest }) => {
  // For user authentication
  const user = sessionStorage.getItem("user_name");
  const userId = sessionStorage.getItem("user_id");

  // For admin authentication
  const adminId = sessionStorage.getItem("admin_id");
  const adminName = sessionStorage.getItem("admin_name");

  // Determine if the route is for user or admin based on the `role` prop
  if (role === "user") {
    // If user data is missing, redirect to login
    return user && userId ? element : <Navigate to="/login" />;
  } else if (role === "admin") {
    // If admin data is missing, redirect to admin login
    return adminId && adminName ? element : <Navigate to="/admin/login" />;
  } else {
    // Default case for any other route (just in case)
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
